import React from 'react'
import { graphql } from 'gatsby'
import loadable from "@loadable/component";
import Seo from "../components/seo";
import Layout from '../components/layout';

const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"));
const StaticIntro = loadable(() => import("../components/StaticIntro/StaticIntro"));
const NewsLanding = loadable(() => import("../components/NewsLanding/NewsLanding"));
const NewsLetterModule = loadable(() => import("../components/NewsLetterModule/NewsLetterModule"));

const NewsLandingTemplate = ({ data }, props) => {
    const PageData = data?.strapiPage
    const allNews = data?.allStrapiBlog.edges

    return (
        <Layout popularSearch={PageData?.select_popular_search?.title}>
            <div className="layout-padding-top"></div>

            <BreadcrumbModule subparentlabel={PageData.choose_menu[0]?.strapi_parent?.title} subparentlink={PageData.choose_menu[0]?.strapi_parent?.slug} parentlabel={PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.title} parentlink={PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.slug} title={PageData.title} />

            {PageData?.Add_Page_Modules?.length > 0 && PageData.Add_Page_Modules?.map((module, index) => {
                return (
                    <>
                        {module.strapi_component === "page-modules.plain-content" && <StaticIntro {...module} pagename={PageData.title} />}
                    </>
                )
            })}

            <NewsLanding allNews={allNews} />

            <NewsLetterModule />
        </Layout>
    )
}

export const Head = ({ data }) => {
    const PageData = data?.strapiPage
    return (
        <Seo title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} />
    )
}


export default NewsLandingTemplate


export const query = graphql`
query ($page_id: String) {
    strapiPage(id: {eq: $page_id}) {
        ...PageFragment
        Add_Page_Modules {
            ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
              ...PlainContentFragment
            }
        }
    }
    allStrapiBlog(filter: {publish: {eq: true}}, sort: {fields: date, order: DESC}) {
        edges {
          node {
            date(formatString: "DD MMM, yyyy")
            title
            slug
            strapi_id
            tile_image {
              alternativeText
              url
            }
            category {
                strapi_json_value
            }
            imagetransforms {
              tile_image_Transforms
            }
            video_url
            short_intro_text
          }
        }
    }
  }
`